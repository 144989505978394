import React from "react"

import Layout from "../components/Layout"
import Container from "../components/Container"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <h1>Seite nicht gefunden...</h1>
      <p>Sie haben eine Seite aufgerufen die nicht exisitiert.</p>
      <Link to="/">Zurück zur Startseite</Link>
    </Container>
  </Layout>
)

export default NotFoundPage
